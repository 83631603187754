.left-prev{
    text-align: left;
}
.right-next{
    text-align: right;
}
.profile{
    text-align: justify;
    margin-right: 40px;
    margin-top: 0;
    padding-top: 0;
}

