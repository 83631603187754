
.Menu-button {
    width: 220px;
    height: 40px;
    color: #000000;
}

.Medium-menu-button {
    width: 220px;
    height: 60px;
    color: #000000;
}

.Big-menu-button {
    width: 220px;
    height: 60px;
    color: #000000;
}
